import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import './style.css'

class Banner extends React.Component {
  state = {
    contentOne: {
      title: 'Over four decades of experience',
      text: 'Creative floors (UK) is committed to bringing you a great level of personal service, with over 40 years of experience in sales and Installations of all types of flooring, both in domestic and commercial situations.',
      image: 'img/new/IMG_4623.jpg'
      // image: 'img/new/IMG_4624.jpg'
      // image: 'img/new/IMG_0733.JPG'
    },
  }

  render() {
    return (
      <section className="content-section container">
        <Container id="clients" className="content-banner" fluid="true">
          <Row className="content content-one">
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 2 }} lg={{ span: 6, order: 1 }} className="content-text">
                <h3>{this.state.contentOne.title}</h3>
                <p>{this.state.contentOne.text}</p>
                <p>We believe that not only do our customers want realistic prices, but also it’s important to have a great quality of service. We all like to deal with people that treat others how they would like to be treated themselves with honest and straight forward answers to any queries. </p>
                <p>We have a good eye for colours, designs, and the latest fashions in flooring and would be only too pleased to advise you should you need our help.</p>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 1 }} lg={{ span: 6, order: 2 }} className="content-image">
                <Image alt={this.state.contentOne.title} src={this.state.contentOne.image} fluid />
            </Col>
          </Row>
          {/* <Row className="content content-two">
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 2 }} lg={{ span: 6, order: 1 }} className="content-image">
                <Image alt={this.state.contentTwo.title} src={this.state.contentTwo.image} fluid />
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 1 }} lg={{ span: 6, order: 2 }} className="content-text">
                <h3>{this.state.contentTwo.title}</h3>
                <p>{this.state.contentTwo.text}</p>
            </Col>
          </Row>
          <Row className="content content-one">
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }} className="content-text">
                <h3>{this.state.contentThree.title}</h3>
                <p>{this.state.contentThree.text}</p>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 2 }} lg={{ span: 6, order: 1 }} className="content-image">
                <Image alt={this.state.contentThree.title} src={this.state.contentThree.image} fluid />
            </Col>
          </Row> */}
        </Container>
      </section>
    );
  }
}

export default Banner;
