import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './style.css'
 
class ImageCarousel extends React.Component {
    state = {
      images: [
        {
          original: '/img/new/IMG_4622.jpg',
          // original: '/img/new/IMG_4621.JPG',
          thumbnail: '/img/new/IMG_4622.jpg',
          originalAlt: 'LVT small Parqet'
        },
        // {
        //   original: '/img/new/IMG_4618.jpg',
        //   thumbnail: '/img/new/IMG_4622.jpg',
        //   originalAlt: 'LVT small Parqet'
        // },
      ]
    } 
 
  render() {  
    return (
      <ImageGallery className="image-carousel"
        items={this.state.images} 
        lazyLoad={false}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    );
  }
 
}
export default ImageCarousel;
