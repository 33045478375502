import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import './style.css'

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: '', name: '', email: '' };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
	  return (
        <section className="contact-section container">
        <Container id="contact-us" className="reviews-grid" fluid="true">
          <h3 className="contact-title text-center">Contact us</h3>
          <Row className="contact-content">
            <Col sm={12} className="contact-col">
            <form className="test-mailing">
              <div className="form-label">
                <label className="label-element">
                  Name
                    <input className="contact-text-field" type="text" value={this.state.name} onChange={this.handleChangeName} placeholder="Full name"/>
                </label>
              </div>
              <div className="form-label">
                <label className="label-element">
                  Email
                    <input className="contact-text-field" type="text" value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email address"/>
                </label>
              </div>
              <div className="form-text-area">
                <label className="label-element">
                  Message
                  <textarea
                    className="contact-text-field"
                    id="test-mailing"
                    name="test-mailing"
                    onChange={this.handleChangeMessage}
                    placeholder="Please enter message here..."
                    required
                    value={this.state.message}
                    style={{width: '100%', height: '150px'}}
                  />
                </label>
              </div>
              <div className="form-text-area text-center">
                <input 
                  type="button" 
                  value="Send" 
                  className="btn btn--submit contact-button-submit" 
                  onClick={this.handleSubmit} 
                />
              </div>
            </form>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="address-text">
              {/* <div class="contact-us-address text-center">
                <h3>Call us</h3>
                <a className="call-us" href="tel:07391721587">07391 721587</a>
              </div> */}
              <div class="contact-us-address text-center">
                <h3>Location</h3>
                <p className="address-list-item">
                  56 Hambro Hill
                </p>
                <p className="address-list-item">
                  Rayleigh
                </p>
                <p className="address-list-item">
                  Essex
                </p>
                <p className="address-list-item">
                  SS6 8BW
                </p>
              </div>
            </Col>

            <Col sm={4} className="address-text">
              <div class="contact-us-address text-center">
                <h3>Call us</h3>
                <p className="address-list-item">
                <a className="call-us" href="tel:07850482422">07850 482422</a>
                </p>
              </div>
            </Col>
              
              <Col sm={4} className="address-text">
                
              <div class="follow-us-social text-center">
                <h3>Follow us</h3>
                <a
                  href="https://www.facebook.com/Creativefloors1/" className="socal-icons-invert facebook social" aria-label="Follow us on Facebook">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                {/* <a
                  href="https://www.instagram.com/ksl_sports_massage" className="socal-icons-invert instagram social" aria-label="Follow us on Instgram">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
	  )
  }

  handleChangeName(event) {
    this.setState({name: event.target.value})
  }

  handleChangeEmail(event) {
    this.setState({email: event.target.value})
  }

  handleChangeMessage(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit (event) {
    const templateId = 'template_creative_floors';

    this.sendMessage(templateId, {message_html: this.state.message, from_name: this.state.name, reply_to: this.state.email})
  }

  sendMessage(templateId, variables) {
    window.emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
          console.log('Email successfully sent!')
          alert('Your request has been submitted');
      })
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
}
