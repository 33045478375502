import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'
 
class Cards extends React.Component {
    state = {
      items: [
        {
          image: 'img/new/kardean-3.jpeg',
          title: 'Massimo',
          text: "New England, laid in 45 degree with framework boarder and Brooklyn heavy domestic carpet on the stairs.",
          button_text: "Enquire now!"
        },
        {
          // image: 'img/',
          image: 'img/new/IMG_4620.JPG',
          title: 'LVT',
          text: "Luxury Vinyl Tile - small Parqet (Holland park). Laid in a herring bone effect, with framework boarder.",
          button_text: "Enquire now!"
        },
        {
            image: 'img/new/IMG_4619.JPG',
            title: 'Brooklyn carpet',
            text: "Laid to landing and stairs and massimo LVT tiles laid to hallway and throughout the rest of the house, with feature strip.",
            button_text: "Enquire now!"
        }
      ]
    }

  render() {  
    return (
      <section className="products-section container">
        <Container id="services" className="products-grid" fluid="true">
        <h1 className="services-title text-center">Services</h1>
        <Row className="client-content">
            { this.state.items.map((item)=> (
                <Col lg={4} md={6} sm={12}>
                <Card className="card-style" >
                    <Card.Img alt={item.title} variant="top" src={item.image} />
                    <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Text>{item.text}</Card.Text>
                        <Button href="#contact-us" variant="primary" className="button-primary">{item.button_text}
                          {/* <AnchorLink className="nav-link nav-link-color" href="#contact-us"></AnchorLink> */}
                        </Button>
                    </Card.Body>
                </Card>
                </Col>
            ))}
            </Row>
        </Container>
      </section>
    );
  }
 
}
export default Cards;
