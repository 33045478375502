import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import './style.css'


class Footer extends React.Component {
  state = {
    footer: {
      backgroundColor: '#292c2f',
      copyright: 'Creative floors',
      links: [
        // {
        //   title: 'About us',
        //   link: '/about-us',
        //   aria_label: 'About us',
        // },
        // {
        //   title: 'Terms & Conditions',
        //   link: '/terms',
        //   aria_label: 'Terms & Conditions',
        // },
        // {
        //   title: 'Privacy Policy',
        //   link: '/privacy',
        //   aria_label: 'Privacy Policy',
        // },
        {
          title: 'Contact us',
          link: '/#contact-us',
          aria_label: 'Contact us',
        },
        // {
        //   title: 'Covid-19',
        //   link: '/covid-19',
        //   aria_label: 'Covid-19 information',
        // },
      ]
    }
  }

  render() {
    return (
      <Container className="footer" fluid="true">
        <Row className="footer footer-links">
          <Col xs={12} lg={6} className="footer-copyright-text text-center">
            <ul className="no-list-style">
              <h5> Useful links</h5>
              { this.state.footer.links.map((item)=> (
                <li>
                  <a href={item.link} className="footer-links text-center" aria-label={item.aria_label}> {item.title} </a>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} lg={6} className="footer-social-icons text-center">
            <h5>Follow us</h5>
                <a
                  href="https://www.facebook.com/Creativefloors1/" className="socal-icons facebook social" aria-label="Follow us on Facebook">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                {/* <a href="https://www.twitter.com/ksl_sports_massage" className="socal-icons twitter social">
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a> */}
                {/* <a
                  href="https://www.instagram.com/ksl_sports_massage" className="socal-icons instagram social" aria-label="Follow us on Instgram">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a> */}
            
          </Col>
        </Row>
      
        <Row className="footer-copyright">
          <Col xs={12} lg={12} className="footer-copyright-text text-center">
            &copy; {new Date().getFullYear()} {this.state.footer.copyright}
          </Col>
        </Row>
      </Container>
    );
      }
}

export default Footer;
