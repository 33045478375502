import React from 'react';
// import { Helmet } from 'react-helmet'
import './App.css';

import Navigation from './components/Navigation';
import AlertBanner from './components/AlertBanner';
import Footer from './components/Footer/index';
import Main from './pages/Main';

function App() {
  const site = {
      name: 'Creative Floors'
  }

  return (
    <div className="App">
      {/* <Helmet>
        <meta name="description" content="I have the unique ability to deliver outstanding Sports Massage Services, customer focus, care and professionalism is always my goal." />
        <meta name="keywords" content="massage,sports massage,deep tissue massage,essex,sports massage essex,basildon,sports massage basildon" />
        <link rel="canonical" href="https://kslsportsmassage.co.uk"/>
      </Helmet> */}
      <Navigation site_name = {site.name} />
      <AlertBanner />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
