import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './style.css'
 
class Cards extends React.Component {
    state = {
      items: [
        {
            quote: '',
            text: "Just had our flooring done with creative floors, we love it! So professional and helpful! We love it thanks so much!",
            referencer: 'Alexia Buttaro',
            last_updated: '2 Aug 2022'
        },
        {
            quote: '',
            text: "Professional and friendly service. Everything done and dusted to time. Prompt communication and a very reliable company. Thank you Sean.",
            referencer: 'Mike Bull',
            last_updated: '26 May 2021'
        },
        {
            quote: '',
            // text: "I have a shoulder condition on one side, which has caused my other shoulder to compensate for. Kelly has visited me for several months now and worked very hard to loosen the knots in my shoulders/back with a variety of techniques, which has helped keep me pain free. She is always friendly and professional, has a great knowledge and often sends a follow up message to check I am okay afterwards. A great massage every time -no need to go anywhere else.",
            text: "I cannot rate Sean highly enough. He did a brilliant job from start to finish in our house. He brought over an amazing selection of carpets to chose from and delivered a very professional job. We are really happy with his work and wouldn't go to anyone else.",
            referencer: 'Jo Van Der Linde',
            last_updated: '6 Oct 2020'
        }
      ]
    } 

  render() {  
    return (
      <section className="reviews-section container">
        <Container id="reviews" className="reviews-grid" fluid="true">
        <h3 className="reviews-title text-center"> Reviews</h3>
        <Row className="client-content">
            { this.state.items.map((item)=> (
                <Col md={4} sm={12}>
                <Card className="text-center review-tile">
                    {/* <Card.Header>Quote</Card.Header> */}
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                        <p>
                            {' '}
                            {item.text}
                            {' '}
                        </p>
                        <footer className="blockquote-footer">
                            {item.referencer}
                        </footer>
                        </blockquote>
                    </Card.Body>
                    <Card.Footer className="">{item.last_updated}</Card.Footer>
                    </Card>
                </Col>
            ))}
            </Row>
        </Container>
      </section>
    );
  }
 
}
export default Cards;






