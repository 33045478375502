import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Terms from './Terms'
import Privacy from './Privacy'
import Covid from './Covid'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
  <main className="container main-container">
    <Switch>
      <Route exact path='/' component={Home}/>
      {/* <Route path='/page/:id' component={Page}/> */}
      {/* <Route path='/terms' component={Terms}/> */}
      <Route path='/privacy' component={Privacy}/>
      {/* <Route path='/covid-19' component={Covid}/> */}
    </Switch>
  </main>
)

export default Main
