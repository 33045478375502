import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './style.css'

class Navigation extends React.Component {
    state = {
        items: {
            left: [],
            right: [
                {
                    id: '',
                    title: 'Home',
                    url: ''
                },
                {
                    id: '',
                    title: 'Services',
                    url: '#services'
                },
                {
                    id: '',
                    title: 'About Us',
                    url: '#clients'
                },
                {
                    id: '',
                    title: 'Reviews',
                    url: '#reviews'
                },
                {
                    id: '',
                    title: 'Contact',
                    url: '#contact-us'
                }
            ]
        }
    }

    render() {
        return (
            // We should allow the nav colours to be configured via strapi
            <section className="nav-section">
                <Navbar sticky="top" expand="lg">
                    <LinkContainer to="/">
                        <Navbar.Brand className="company-logo">
                            {/* <img
                                src="/img/logos/ksl_logo_trans_blue_cropped.png"
                                width="100"
                                height="60"
                                className="d-inline-block align-top nav-logo center"
                                alt="KSL Sports massage logo"
                            /> */}
                            Creative Floors
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            { this.state.items.left.map((item)=> (
                                <LinkContainer to={`/${item.url}`} className="menu-link-item">
                                    <Nav.Link>{item.title}</Nav.Link>
                                </LinkContainer>
                            ))}
                        </Nav>
                        <Nav>
                            { this.state.items.right.map((item)=> (
                                <AnchorLink className="nav-link nav-link-color menu-link-item" href={`${item.url}`}>{item.title}</AnchorLink>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </section>
        )
    }
}

export default Navigation;
